export default {
    methods: {
        getWorkDatas: function(data, callback) {
            this.$store.dispatch('getDoctorWeekWorkDate', data)
            .then((data)=>{
                var list = []
                var datas = ((data && data.workDateList) || []).splice(0, 7)
                datas.forEach((d, i)=>{
                    var date = d.ScheduleDate
                    var _d = new Date(date)
                    var week = _d.getDay()
                    var start = !d.StartTime ? '' : d.StartTime.substring(0, 5)
                    var end = !d.EndTime ? '' : d.EndTime.substring(0, 5)
                    list.push({
                        'range': `${start}~${end}`,
                        'date': date,
                        'week': ['星期日','星期一','星期二','星期三','星期四','星期五','星期六'][week],
                        'checked': i == 0
                    })
                })
                callback && callback(list, null)
            })
            .catch((error)=>{
                callback && callback(null, error)
            })
        },
        getWorkTimes: function(data, callback) {
            this.$store.dispatch('getDoctorWeekWorkTimes', data)
            .then((data)=>{
                var list = []
                var split1 = new Date("2000/01/01 13:00:00").getTime()
                var split2 = new Date("2000/01/01 19:00:00").getTime()
                data.forEach(d=>{
                    var type = 0
                    var endTime = new Date("2000/01/01 "+d.EndTime).getTime()
                    if(endTime < split1) {
                        // 上午
                        type = 0
                    } else if(endTime <= split2 && endTime > split1) {
                        //下午
                        type = 1
                    } else if(endTime > split2) {
                        //晚上
                        type = 2
                    }
                    list.push({
                        id: d.ScheduleID,
                        time: `${d.StartTime.substring(0, 5)}-${d.EndTime.substring(0, 5)}`,
                        type,
                    })
                })
                callback && callback(list, null)
            })
            .catch((error)=>{
                callback && callback(null, error)
            })
        },
        addBooking: function(data, callback) {
            this.$store.dispatch('addBooking', data)
            .then((rs)=>{
                callback && callback(rs, null)
            })
            .catch((error)=>{
                callback && callback(null, error)
            })
        },
    }
}