<template>
    <!-- 预约窗口 -->
    <div class="dialog booking" @click.self="dismissDialog">
        <div class="dialog-container">
            <div class="title-bar">
                <span class="title">預約醫師-{{doctorInfo.username}}</span>
                <span class="close-dialog" @click="dismissDialog"></span>
            </div>
            <div class="doctor-from-clinics" v-if="clinicList.length">
                <span class="hint">选择诊所：</span>
                <a-select :default-value="doctorInfo.clinicid" @change="onClinicChanged"  v-if="clinicList.length" style="flex: 1;">
                    <a-select-option :value="clinic.clinicid" v-for="(clinic, i) in clinicList" :key="i">
                        {{clinic.clinicname}}
                    </a-select-option>
                </a-select>
            </div>
            <div class="doctor-info">
                <span :class="{'doctor-avatar': true, 'man': doctorInfo.sex == 1}"></span>
                <div class="doctor-info-t">
                    <span class="clinic-name" v-text="doctorInfo.clinicname"></span>
                    <span class="doctor-name" v-text="'醫師：'+doctorInfo.username"></span>
                </div>
                <div class="doctor-info-b">
                    <span class="doctor-tel" v-text="doctorInfo.contactnum"></span>
                    <span class="clinic-address" v-text="doctorInfo.clinicaddr"></span>
                </div>
            </div>
            <div class="booking-content" :style="{'transform': 'translateX('+translateX+'px)'}">
                <div class="booking-times">
                    <div class="booking-time-show" v-if="!emptyTime">
                        <span class="booking-time-hint">預約時間</span>
                        <div class="checked-booking-time" v-show="checkedTime">
                            <span class="checked-time-hint">已選擇:</span>
                            <div class="time" v-text="checkedTime"></div>
                        </div>
                    </div>
                    <div class="doctor-work-time-choose">
                        <div class="doctor-work-day">
                            <div @click="onDateItemClick(d)" :class="{'doctor-work-day-item': true, 'checked': d.checked}" v-for="(d,i) in workDates" :key="i" v-text="`${d.date} (${d.range}) ${d.week}`"></div>
                        </div>
                        <div class="doctor-work-time">
                            <div class="row">
                                <div class="split">
                                    <div :class="{'doctor-work-time-item': true, 'checked': item.checked}" v-for="(item, index) in workTimes.filter(t=>t.type==0)" :key="index" v-text="item.time" @click="onTimeItemClick(item)"></div>
                                </div>
                                <div class="split-item" v-if="workTimes.filter(t=>t.type==0).length">上午</div>
                            </div>
                            <div class="row">
                                <div class="split">
                                    <div :class="{'doctor-work-time-item': true, 'checked': item.checked}" v-for="(item, index) in workTimes.filter(t=>t.type==1)" :key="index" v-text="item.time" @click="onTimeItemClick(item)"></div>
                                </div>
                                <div class="split-item" v-if="workTimes.filter(t=>t.type==1).length">下午</div>
                            </div>
                            <div class="row">
                                <div class="split">
                                    <div :class="{'doctor-work-time-item': true, 'checked': item.checked}" v-for="(item, index) in workTimes.filter(t=>t.type==2)" :key="index" v-text="item.time" @click="onTimeItemClick(item)"></div>
                                </div>
                                <div class="split-item" v-if="workTimes.filter(t=>t.type==2).length">晚上</div>
                            </div>
                            <loading v-if="loadingTime" style="padding-top: 50px;" />
                        </div>
                        <loading v-if="loading" style="padding-top: 50px;" />
                    </div>
                    <div class="unset-time" v-if="emptyTime">此醫師未進行預約時間設定，不能進行預約操作</div>
                </div>
                <div class="booking-description">
                    <div class="booking-info">
                        <span class="user" v-text="userInfo.clientname"></span>
                        <div class="booking-c-time" v-text="checkedTime"></div>
                        <a href="javascript:;" class="update-booking-time" @click="toPrev">修改</a>
                    </div>
                    <textarea rows="6" class="symptom-info" placeholder="請簡述您的症狀，500字以內" v-model="note"></textarea>
                    <div class="booking-notice">
                        預約須知：<br/>
                        1、請您在預約和就診時，提供真實、有效的實名身份信息和證件。<br/>
                        2、突發原因有可能導致門診時間改變，請以醫院當日公佈為准，或聯繫醫院確認。<br/>
                        3、醫師門診資源有限，如預約成功後因故確定不能按時就診，請務必於就診日前一天17：00前及時取消預約，否則按違約處理，累計三<br/>
                        次，您將無法進行預約。<br/>
                        4、為保證您準時就診，請於就診當日按所預約時間段提前30分鐘到門診大廳取號，逾期作廢。</div>
                </div>
            </div>
            <div :class="{'btn-next': true, 'enable': checkedTime}" @click="toNext" v-if="workTimes.length && pageIndex == 0">下一步</div>
            <div class="btn-submit-booking" v-if="pageIndex == 1" @click="submitBooking"><div class="lds-ring1" v-if="submiting"><div></div><div></div><div></div><div></div></div>{{loading ? '正在提交...' : '提交预约'}}</div>
        </div>
    </div>
</template>

<script>
    import booking_time from '@/views/mixins/booking_time'
    import Loading from '@/layouts/Loading.vue'
    export default {
        components: {
            Loading
        },
        mixins: [booking_time],
        props: {
            callback: Function,
            doctor: null,
        },
        data() {
            return {
                translateX: 0,
                pageIndex: 0,
                loading: true,
                loadingTime: false,
                workDates: [],
                workTimes: [],
                checkedTime: '',
                note: '',
                checkedDate: '',
                checkedTimeId: '',
                userInfo: this.$store.getters.userInfo,
                submiting: false,
                clinicList: [],
                doctorInfo: {... this.$props.doctor},

                emptyTime: false,
            }
        },
        mounted: function(){
            this.$store.dispatch('getClinicListByDoctor', {globaluserid: this.doctorInfo.globaluserid})
            .then((list) => {
                this.clinicList = list
            })
            this.loadData()
        },
        methods: {
            onClinicChanged: function(clinicid) {
                var clinic = this.clinicList.find((d)=>d.clinicid==clinicid)
                this.doctorInfo.userid = clinic.userid
                this.doctorInfo.clinicname = clinic.clinicname
                this.doctorInfo.contactnum = clinic.contacttel
                this.doctorInfo.clinicaddr = clinic.address

                this.workDates = []
                this.workTimes = []
                this.checkedTime = ''
                this.note = ''
                this.checkedDate = ''
                this.checkedTimeId = ''
                this.translateX = 0

                this.loadingTime = true
                this.loadData()
            },
            loadData: function() {
                this.getWorkDatas({doctorId: this.doctorInfo.userid}, (data, error)=>{
                    this.loading = false
                    if(error) {
                        this._toast.warning(this, error)
                        this.dismissDialog()
                    } else {
                        this.workDates = data
                        if(this.workDates.length) {
                            this.emptyTime = false
                            this.loadingTime = true
                            this.checkedDate = this.workDates[0].date
                            this.getWorkTimes(
                            {
                                doctorId: this.doctorInfo.userid, 
                                scheduleDate: this.workDates[0].date
                            }, (times, error)=>{
                                this.loadingTime = false
                                if(times) {
                                    this.workTimes = times
                                } else {
                                    if(error) {
                                        this._toast.warning(this, error)
                                    }
                                }
                            })
                        } else {
                            this.loadingTime = false
                            this.emptyTime = true
                        }
                    }
                })
            },
            dismissDialog: function() {
                this.callback && this.callback()
            },
            toNext: function() {
                if(!this.checkedTime) {
                    this._toast.warning(this, '請選擇預約時間')
                    return
                }
                this.translateX = -936
                this.pageIndex = -1
                setTimeout(() => {
                    this.pageIndex = 1
                }, 500);
            },
            toPrev: function() {
                this.translateX = 0
                this.pageIndex = -1
                setTimeout(() => {
                    this.pageIndex = 0
                }, 500);
            },
            onDateItemClick: function(d) {
                if(d.isChecked) {
                    return
                }
                this.loadingTime = true
                this.checkedTime = ''
                this.workDates.filter(date=>date != d).forEach(date=>date.checked = false)
                d.checked = true
                this.checkedDate = d.date
                this.workTimes = []
                this.getWorkTimes(
                {
                    doctorId: this.doctorInfo.userid, 
                    scheduleDate: d.date
                }, (times, error)=>{
                    this.loadingTime = false
                    if(times) {
                        this.workTimes = times
                    } else {
                        if(error) {
                            this._toast.warning(this, error)
                        }
                    }
                })
            },
            onTimeItemClick: function(time){
                this.workTimes.forEach(t=>t.checked=false)
                time.checked = true
                this.workTimes = [...this.workTimes]
                var d = this.workDates.find(d=>d.checked)
                this.checkedTime = `${d.date} ${time.time}(${d.week})`
                this.checkedTimeId = time.id
            },
            submitBooking: function(){
                var params = {
                    clientId: this.$store.getters.clientId,
                    doctorId: this.doctorInfo.userid,
                    note: this.note,
                    scheduleDate: this.checkedDate,
                    scheduleID: this.checkedTimeId,
                    sourceType: 'F',
                }
                this.submiting = true
                this.addBooking(params, (data, error)=>{
                    this.submiting = false
                    if(error) {
                        this._toast.warning(this, error)
                    } else {
                        this._toast.success(this, '預約成功')
                        this.dismissDialog()
                    }
                })
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .dialog {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1000;
        background-color: rgba(4, 58, 61, 0.3);
        overflow: hidden;
    }
    .dialog > .dialog-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 936px;
        height: 680px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .dialog> .dialog-container .title-bar {
        display: inline-block;
        height: 46px;
        line-height: 46px;
        background-color: #2B99A6;
        padding-left: 30px;
        font-weight: bold;
        font-size: 20px;
        color: #fff;
    }

    .dialog > .dialog-container .title-bar .close-dialog {
        float: right;
        width: 46px;
        height: 46px;
        position: relative;
        background-color: #36C4D0;
        cursor: pointer;
    }

    .dialog > .dialog-container .title-bar .close-dialog::after {
        width: 20px;
        height: 20px;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-image: url(../assets/imgs/pc/img_cha_white@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .dialog > .dialog-container .btn-submit-booking,
    .dialog > .dialog-container .btn-next {
        cursor: pointer;
        width: 229px;
        height: 44px;
        border-radius: 10px;
        background-color: #E3EFF2;
        line-height: 44px;
        text-align: center;
        color: #969696;
        font-weight: bold;
        font-size: 18px;
        margin: 20px auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* 預約掛號 start */
    .dialog.booking .doctor-from-clinics {
        line-height: 36px;
        position: relative;
        display: flex;
        margin-top: 30px;
        margin-left: 36px;
        margin-right: 36px;
        font-size: 18px;
        color: #1B2122;
        font-weight: 400;
    }

    .ant-select-selection {
        flex: 1;
    }

    .dialog.booking .booking-content .booking-time-show {
        width: 823px;
        height: 18px;
        margin: 40px 77px 14px 36px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .dialog.booking .booking-content .booking-time-show > .booking-time-hint {
        line-height: 18px;
        padding-left: 28px;
        font-size: 18px;
        color: #1B2122;
        position: relative;
    }

    .dialog.booking .booking-content .booking-time-show .booking-time-hint::before {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        height: 18px;
        width: 18px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../assets/imgs/pc/img_rili@2x.png);
    }

    .dialog.booking .booking-content .booking-time-show .checked-booking-time {
        display: flex;
        flex-direction: row;
        position: relative;
    }

    .dialog.booking .booking-content .booking-time-show .checked-booking-time > .checked-time-hint {
        line-height: 15px;
        font-size: 16px;
        color: #969696;
        margin-right: 24px;
        font-weight: bold;
    }

    .dialog.booking .booking-content .booking-time-show .checked-booking-time > .time {
        line-height: 15px;
        font-size: 16px;
        color: #EE1B23;
        position: relative;
        font-weight: bold;
    }

    .dialog.booking .booking-content .doctor-work-time-choose {
        display: flex;
        flex-direction: row;
        height: 237px;
        width: 860px;
        margin-left: 36px;
        position: relative;
    }

    .dialog.booking .booking-content .doctor-work-time-choose .doctor-work-split {
        width: 32px;
    }

    .dialog.booking .booking-content .doctor-work-time-choose .split-item {
        width: 32px;
        white-space: break-spaces;
        background-color: #E3EFF2;
        padding: 0 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 60px;
        border-bottom: 2px solid #fff;
    }

    .dialog.booking .booking-content .doctor-work-time-choose .doctor-work-split > .noon {
        padding: 42px 8px;
        height: 115px;
        line-height: 15.5px;
    }

    .dialog.booking .booking-content .doctor-work-time-choose .doctor-work-split > .split-item:nth-child(1) {
        margin-top: 0px;
    }

    .dialog.booking .booking-content .doctor-work-time-choose > .doctor-work-day {
        flex: 1;
    }

    .dialog.booking .booking-content .doctor-work-time-choose .doctor-work-day-item {
        width: 285px;
        height: 33px;
        line-height: 33px;
        margin-top: 1px;
        background-color: #E3EFF2;
        font-weight: bold;
        color: #1B2122;
        font-size: 16px;
        text-align: center;
        border: 1px solid #E3EFF2;
        box-sizing: border-box;
        cursor: pointer;
    }

    .dialog.booking .booking-content .doctor-work-time-choose .doctor-work-day-item.checked {
        background-color: #2B99A6;
        color: #fff;
    }

    .dialog.booking .booking-content .doctor-work-time-choose .doctor-work-day-item:hover {
        box-sizing: border-box;
        border: 1px solid #2B99A6;
    }

    .dialog.booking .booking-content .doctor-work-time-choose .doctor-work-day-item:nth-child(1) {
        margin-top: 0px;
    }

    .dialog.booking .booking-content .doctor-work-time-choose > .doctor-work-time {
        flex: 1;
        margin-left: 10px;
        position: relative;
    }

    .dialog.booking .booking-content .doctor-work-time-choose > .doctor-work-time > .row {
        display: flex;
        flex-direction: row;
        margin-bottom: 4px;
    }

    .dialog.booking .booking-content .doctor-work-time-choose > .doctor-work-time .split {
        min-height: 58px;
        margin-right: 9px;
        width: 524px;
    }

    .dialog.booking .booking-content .doctor-work-time-choose > .doctor-work-time .doctor-work-time-item {
        width: 130px;
        height: 28px;
        line-height: 28px;
        font-weight: 400;
        font-size: 16px;
        color: #1B2122;
        background-color: #E3EFF2;
        text-align: center;
        float: left;
        margin-right: 1px;
        margin-bottom: 1px;
        cursor: pointer;
        box-sizing: border-box;
        border: 1px solid #E3EFF2;
    }

    .dialog.booking .booking-content .doctor-work-time-choose > .doctor-work-time .doctor-work-time-item.checked {
        color: #fff;
        background-color: #2B99A6;
        box-sizing: border-box;
        border: 1px solid #2B99A6;
    }

    .dialog.booking .booking-content .doctor-work-time-choose > .doctor-work-time .doctor-work-time-item:hover {
        box-sizing: border-box;
        border: 1px solid #2B99A6;
    }

    .dialog.booking .doctor-info {
        width: 864px;
        height: 64px;
        margin: 0px auto;
        box-sizing: border-box;
        border: 1px solid #E3EFF2;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top: 40px;
    }

    .dialog.booking .doctor-info .doctor-info-t {
        display: flex;
        flex-direction: column;
    }

    .dialog.booking .doctor-info .doctor-info-b {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 50px;
    }

    .dialog.booking .doctor-info > .doctor-avatar {
        width: 44px;
        height: 44px;
        margin: 0px 22px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../assets/imgs/pc/img_nanysy@2x.png);
    }

    .dialog.booking .doctor-info > .doctor-avatar.man {
        background-image: url(../assets/imgs/pc/img_nvyis@2x.png);
    }

    .dialog.booking .doctor-info .clinic-name {
        font-size: 18px;
        font-weight: bold;
        line-height: 18px;
        color: #231F20;
    }

    .dialog.booking .doctor-info .doctor-name {
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        color: #636363;
        margin-top: 10px;
    }

    .dialog.booking .doctor-info .doctor-tel {
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        color: #231F20;
        position: relative;
        padding-left: 24px;
    }

    .dialog.booking .doctor-info .doctor-tel::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 14px;
        height: 14px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../assets/imgs/pc/img_iphone@2x.png);    
    }

    .dialog.booking .doctor-info .clinic-address {
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        color: #231F20;
        margin-top: 12px;
        position: relative;
        padding-left: 24px;
    }

    .dialog.booking .doctor-info .clinic-address::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 11px;
        height: 14px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../assets/imgs/pc/img_dib@2x.png);    
    }

    .dialog.booking .booking-content {
        width: 1872px;
        white-space: nowrap;
        transition: all .3s ease-in-out;
        transform: translateX(0);
    }

    .dialog.booking .booking-content.next-page {
        transform: translateX(-50%);
    }

    .dialog.booking .booking-content > .booking-times {
        width: 936px;
        height: 379px;
        float: left;
        position: relative;
    }

    .dialog.booking .booking-content > .booking-times .unset-time {
        position: absolute;
        left: 50%;
        top: 120px;
        color: #a1a0a0;
        font-size: 29px;
        font-weight: 400;
        transform: translateX(-50%);
    }
    .dialog.booking .booking-content > .booking-description {
        width: 936px;
        height: 379px;
        float: left;
    }

    .dialog.booking .booking-content > .booking-description > .booking-info {
        display: flex;
        flex-direction: row;
        height: 18px;
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .dialog.booking .booking-content > .booking-description > .booking-info > .user {
        height: 18px;
        line-height: 18px;
        font-size: 18px;
        font-weight: bold;
        color: #1B2122;
        padding-left: 27px;
        position: relative;
        margin-left: 36px;
    }

    .dialog.booking .booking-content > .booking-description > .booking-info > .user::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 16px;
        height: 15px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../assets/imgs/pc/img_ren@2x.png);
    }

    .dialog.booking .booking-content > .booking-description > .booking-info > .booking-c-time {
        margin-left: 80px;
        padding-left: 24px;
        position: relative;
        height: 18px;
        line-height: 18px;
        color: #1B2122;
        font-size: 18px;
        font-weight: bold;
    }

    .dialog.booking .booking-content > .booking-description > .booking-info > .booking-c-time::before {
        position: absolute;
        content: '';
        left: 0px;
        top: 0px;
        width: 15px;
        height: 16px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../assets/imgs/pc/img_mril@2x.png);
    }

    .dialog.booking .booking-content > .booking-description > .booking-info > .update-booking-time {
        margin-left: 20px;
        height: 18px;
        line-height: 18px;
        color: #FF8000;
        font-size: 16px;
        font-weight: 400;
        text-decoration: underline;
    }

    .dialog.booking .booking-content > .booking-description > .symptom-info {
        background-color: #E3EFF2;
        color: #231F20;
        padding: 16px 20px;
        width: 824px;
        margin-left: 36px;
    }

    .dialog.booking .booking-content > .booking-description > .booking-notice {
        margin: 10px 36px 0px 36px;
        color: #969696;
        line-height: 19px;
        font-size: 14px;
        font-weight: 400;
    }

    .dialog > .dialog-container .btn-submit-booking,
    .dialog > .dialog-container .btn-next.enable {
        background-color: #2B99A6;
        color: #fff;
    }
    /* 預約掛號 end */


	/** loading start */
    .lds-ring1 {
        display: inline-block;
        position: relative;
        width: 32px;
        height: 32px;
    }
    
    .lds-ring1 div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 24px;
        height: 24px;
        margin: 4px;
        border: 4px solid #fff;
        border-radius: 50%;
        animation: lds-ring1 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    
    .lds-ring1 div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring1 div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring1 div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring1 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    /** loading end */ 
</style>